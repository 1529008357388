import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { Res } from "../../../utils/constants"
import { palette } from "../../../styles"

export const BannerOffer = styled.a`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1280px;
  height: 100%;

  margin: 10px 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #fff;

  border-radius: 12px;
`

export const BannerMobile = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
`

export const Inner = styled.div`
  position: absolute;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px 30px 10px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 20px 30px 10px;
  }

  @media (max-width: 512px) {
    justify-content: center;
    padding: 12px 15px 10px;

    width: 70%;
    height: 100%;
    max-height: 145px;
  }

  @media (max-width: 374px) {
    padding: 12px 15px 10px;
    width: 74%;
  }
`

export const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  max-width: 55%;

  a {
    max-width: fit-content;
  }

  @media (max-width: 512px) {
    max-width: 55%;
    a {
      min-height: 40px;
      height: 40px;
    }
  }

  @media (max-width: 512px) {
    max-width: 55%;
  }

  a {
    flex: 1 1 auto;
  }

  @media (max-width: 860px) and (min-width: 513px) {
    max-width: 160px;
  }
`

export const Terms = styled.span`
  padding: 10px 0;

  font-size: 1.2rem;

  @media (max-width: 900px) and (min-width: 700px) {
    padding: 5px 0;
  }

  @media (max-width: 512px) {
    font-size: 0.7em;
    line-height: 0.7em;
    padding: 10px 0 5px 0;
  }

  @media (max-width: 374px) {
    font-size: 0.7em;
    line-height: 0.9rem;

    padding: 5px 0 5px 0;
  }
`

export const Markdown = styled(ReactMarkdown)`
  h1,
  h2 {
    margin: 0;
  }

  h1 {
    font-size: 3em;
    line-height: 1em;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 1.8em;
    line-height: 1em;
    font-weight: 100;

    strong {
      font-weight: 100;
    }
  }

  @media (max-width: 1024px) and (min-width: 900px) {
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 1.7em;
    }
  }

  @media (max-width: 900px) and (min-width: 700px) {
    h1 {
      font-size: 2.1em;
    }
    h2 {
      font-size: 1.6em;
    }
  }

  @media (max-width: 700px) and (min-width: 513px) {
    h1 {
      font-size: 3em;
      line-height: 6rem;
    }
    h2 {
      font-size: 2em;
      line-height: 2.5rem;
    }
  }

  @media (max-width: 512px) {
    h1 {
      font-size: 1.6em;
      line-height: 2rem;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 1.4em;
      line-height: 1.5rem;
    }
  }

  @media (max-width: 375px) {
    h1 {
      margin-top: 5px;
      font-size: 1.5em;
      line-height: 1.5rem;
      margin-bottom: 5px;
    }
    h2 {
      font-size: 1.2em;
      line-height: 1.5rem;
    }
  }
`
export const Ribbon = styled.div`
  position: absolute;
  bottom: 7px;
  right: 0;
  z-index: 11;

  height: 17px;
  padding: 2px 10px 2px 5px;
  margin-left: auto;

  background-color: #fff;

  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${palette.crusta};

  :before {
    content: "";
    display: block;

    z-index: -1;

    position: absolute;
    top: 0;
    left: -10px;

    background-color: #fff;
    height: 21px;
    width: 25px;
    transform: skew(-15deg);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  @media (min-width: 512px) {
    height: 30px;
    line-height: 30px;
    padding: 2px 20px 2px 15px;

    :before {
      height: 34px;
      transform: skew(-15deg);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  span {
    position: relative;
    font-size: 1.2rem;
  }
`

export const RibbonText = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    display: inline-flex;
  }

  img {
    max-height: 28px;
    margin-right: 20px;
  }

  @media (max-width: ${Res._768}px) {
    font-size: 11px;

    img {
      max-height: 20px;
      margin-right: 10px;
    }
  }
`
